import React from 'react';
import ztlogo from '../../img/ZT_logo_white_gold.svg';
import './Footer.scss';

const Footer = () => (
  <footer id="Footer">
    <div className="Footer__wrapper">
      <div className="Footer__copyright">
        {`© ${new Date().getFullYear()} ApplyCycle`}
      </div>
      <div className="Footer__logo">
        <span>A</span>
        <a href="https://www.zivtech.com" className="Footer__logo-link">
          <img
            src={ztlogo}
            alt="Zivtech company logo"
            className="Footer__logo-image"
          />
        </a>
        <span>Product</span>
      </div>
    </div>
  </footer>
);

export default Footer;
