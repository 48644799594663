import React from 'react';
import { Link } from 'gatsby';
import * as Utils from '../../js-utils/utils.js'
import './Nav.scss';

class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      activeItem: null
    }
    this.toggleMenu = this.toggleMenu.bind(this)
  }

  toggleMenu() {
    this.setState(prevState => ({
      open: !prevState.open,
    }))

    //@TODO: Set this class on the body in a more React-friendly way.
    document
      .getElementsByTagName('body')
      .item(0)
      .classList.toggle('menu-is-open')
  }

  closeMenu() {
    this.setState({
      open: false
    })

    //@TODO: Set this class on the body in a more React-friendly way.
    document
      .getElementsByTagName('body')
      .item(0)
      .classList.remove('menu-is-open')
  }

  navItems = [
    {
      name: 'Features',
      path: '/features',
    },
    {
      name: 'How It Works',
      path: '/how-it-works',
    },
    {
      name: 'Documentation',
      path: '/docs',
    },
    {
      name: 'Contact',
      path: '/contact',
    },
  ]

  componentDidMount() {
    const firstPathPart = Utils.stripSlashes(window.location.pathname).split('/')[0];
    const active = this.navItems.filter(item => Utils.stripSlashes(item.path) === firstPathPart);

    if (active[0]) {
      this.setState({
        activeItem: active[0].path,
      })
    }
  }

  render() {
    return (
      <div className="Nav">
        <button
          className={'Nav__toggle ' + (this.state.open ? 'is-active' : '')}
          onClick={this.toggleMenu}
          type="button"
        >
          <span className="line" role="presentation" />
          <span className="line" role="presentation" />
          <span className="line" role="presentation" />
          <span className="hidden">Open mobile menu</span>
        </button>
        <div className={ "Nav__wrapper " + (this.state.open ? 'open' : 'closed') }>
          <nav className="Nav__list">
            { this.navItems.map((item, index) => (
              <div key={ item.path } className={`Nav__item-wrapper ${this.state.activeItem === item.path ? 'active' : 'inactive'}`}>
                <Link
                  className="Nav__item"
                  to={ item.path }
                  onClick={ () => this.closeMenu() }
                >
                  { item.name }
                </Link>
              </div>
            ))}
          </nav>
          <div
            className="Nav__overlay"
            onClick={ () => this.closeMenu() }
            role="presentation"
          />
        </div>
      </div>
    )
  }
}

export default Nav;
