import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from "gatsby";
import Helmet from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';
import './sass/layout.scss';
import './sass/typography.scss';


const Layout = ({color, children}) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet title={ data.site.siteMetadata.title }>
          <html lang="en" />
        </Helmet>
        <a href="#content-wrapper" className="skip-link">Skip to main content</a>
        <Header />
        <div id="content-wrapper" tabIndex="-1">
          <div id="content" className={ `bg-${color}` }>
            { children }
          </div>
        </div>
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  color: PropTypes.oneOf(['0', '1', '2', '3', '4']),
}

Layout.defaultProps = {
  color: '0',
}

export default Layout;

export const siteTitleFragmentQuery = graphql`
  fragment siteTitle on Query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export const ctaFragmentQuery = graphql`
  fragment cta on Query {
    ctaMarkdown: markdownRemark(
      frontmatter: { key: { eq: "cta" }, uid: { eq: "1" } }
    ) {
      frontmatter {
        title
        button {
          link
          text
        }
      }
    }
  }
`

export const docsMenuQuery = graphql`
  fragment docsMenu on Query {
    file(name: { eq: "docs-menu" }) {
      childMarkdownRemark {
        frontmatter {
          items_1 {
            label
            path
            items_2 {
              label
              path
              items_3 {
                label
                path
              }
            }
          }
        }
      }
    }
  }
`
