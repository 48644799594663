import React from 'react';
import { Link } from 'gatsby';
import Nav from '../Nav';
import logo from '../../img/logo.svg';
import './Header.scss';

const Header = () => (
  <header id="Header">
    <div className="Header__wrapper">
      <div className="Header__logo">
        <Link to="/" title="Home">
          <figure>
            <img src={ logo } alt="ApplyCycle" />
          </figure>
        </Link>
      </div>
      <Nav />
    </div>
  </header>
);

export default Header;
